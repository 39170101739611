import React, { useState } from 'react';
import classNames from 'classnames';
import { SectionProps } from '../../utils/SectionProps';
import Image from '../elements/Image';

const propTypes = {
  ...SectionProps.types
}

const defaultProps = {
  ...SectionProps.defaults
}

const Hero = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  ...props
}) => {

  const outerClasses = classNames(
    'hero section banner',
    topOuterDivider && 'has-top-divider',
    bottomOuterDivider && 'has-bottom-divider',
    hasBgColor && 'has-bg-color',
    invertColor && 'invert-color',
    className
  );

  const innerClasses = classNames(
    'hero-inner section-inner',
    topDivider && 'has-top-divider',
    bottomDivider && 'has-bottom-divider'
  );

  return (
    <section
      {...props}
      className={outerClasses}
    >
      <div className="container-sm">
        <div className={innerClasses}>
          <div className="hero-banner">

            <div className="hero-image reveal-from-bottom center-content" data-reveal-value="20px" data-reveal-delay="800">
              <Image
                className=""
                src={require('./../../assets/images/business.png')}
                alt="Business"
                width={435}
                height={346} />
            </div>

            <div className="hero-content">
              <h1 className="mt-0 mb-16 reveal-from-bottom" data-reveal-delay="200">
                Digitalwoven
              </h1>
              <div className="container-xs">
                <p className="m-0 mb-32 reveal-from-bottom text-color-light" data-reveal-delay="400">
                  The primary business DigitalWoven is blockchain technology and A.I. , We have three products: UTStamp, Anylocal, and DigitalCredit.
                </p>
              </div>
            </div>

          </div>
        </div>
      </div>
    </section>
  );
}

Hero.propTypes = propTypes;
Hero.defaultProps = defaultProps;

export default Hero;