import React from 'react';
import { Link } from "react-router-dom";
import classNames from 'classnames';
import { SectionSplitProps } from '../../utils/SectionProps';
import SectionHeader from './partials/SectionHeader';
import Image from '../elements/Image';
import ButtonGroup from '../elements/ButtonGroup';
import Button from '../elements/Button';
import UtStamp from '../../views/UtStamp';

const propTypes = {
  ...SectionSplitProps.types
}

const defaultProps = {
  ...SectionSplitProps.defaults
}

const UtstampInfo = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  invertMobile,
  invertDesktop,
  alignTop,
  imageFill,
  ...props
}) => {

  const outerClasses = classNames(
    'features-split section',
    topOuterDivider && 'has-top-divider',
    bottomOuterDivider && 'has-bottom-divider',
    hasBgColor && 'has-bg-color',
    invertColor && 'invert-color',
    className
  );

  const innerClasses = classNames(
    'features-split-inner section-inner',
    bottomDivider && 'has-bottom-divider'
  );

  const splitClasses = classNames(
    'info-wrap',
    invertMobile && 'invert-mobile',
    invertDesktop && 'invert-desktop',
    alignTop && 'align-top'
  );

  const sectionHeader = {
    title: 'UTStamp',
    paragraph: 'Blockchain Plug and Play'
  };

  return (
    <section
      {...props}
      className={outerClasses}
    >
      <div className="container mt-32">
        <div className={innerClasses}>
          <SectionHeader data={sectionHeader} className="center-content has-top-divider pt-32" />
          <div className={splitClasses}>

            <div className="info-item">
              <div className="info-item-content" data-reveal-container=".info-item">
                <p className="m-0 text-14">
                  UTStamp system is an open blockchain system that reliably keeps and tracks digital assets at low cost. It produces a unique digital mark for each digital asset (document, picture, digital currency, among others) that can be verified with the combination of Bitcoin’s blockchain and UTStamp blockchain for the following attributes:
                </p>
                <ul className="text-14">
                  <li>Proof of the time when a document is registered to the UTStamp chain</li>
                  <li>Proof of the contents of the document</li>
                  <li>
                    Absolute time stamp – For every one minute, UTStamp will generate a universally unique
                    digital
                    signature + QR code for the corresponding time point so that the time signature is not
                    predictable
                    until the time is present.
                        </li>
                  <li>The digital mark generated by the UTStamp system cannot be altered once it is created and it
                      can be
                      mathematically verified by any 3rd party with Bitcoin’s blockchain and the UTStamp chain
                      based on the
                            UTStamp published algorithm.</li>
                  <li>
                    UTStamp works across public cloud platforms and public blockchain systems.
                        </li>
                  <li>
                    UTStamp is now running on multiple cloud platforms, Amazon AWS, Microsoft Azure and Ali Yun,
                    and it
                    can be deployed on any private cloud system while seamlessly integrating with UTStamp public
                    chain
                    system.
                        </li>
                  <li>
                    Today, the UTStamp chain is integrated with Bitcoin and Ethereum.
                        </li>
                </ul>
                <p className="m-0 text-14">
                  The UTStamp system is very scalable and cost efficient and can stamp very large volume (billions per second) of digital assets with Bitcoin’s blockchain backed digital mark. UTStamp also provides public API and SDK for 3rd party integration.
                </p>
              </div>
              <div className={
                classNames(
                  'info-item-image center-content-mobile',
                  imageFill && 'info-item-image-fill'
                )}
                data-reveal-container=".info-item">
                <Image
                  src={require('./../../assets/images/network.svg')}
                  alt="network"
                  width={240}
                  height={240} />
              </div>
            </div>

          </div>
        </div>
      </div>
    </section>
  );
}

UtstampInfo.propTypes = propTypes;
UtstampInfo.defaultProps = defaultProps;

export default UtstampInfo;