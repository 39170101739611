import React from 'react';
import classNames from 'classnames';
import Image from '../elements/Image';
import { SectionTilesProps } from '../../utils/SectionProps';
import SectionHeader from './partials/SectionHeader';

const propTypes = {
  ...SectionTilesProps.types
}

const defaultProps = {
  ...SectionTilesProps.defaults
}

const UtstampFeatures = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  pushLeft,
  ...props
}) => {

  const outerClasses = classNames(
    'testimonial section',
    topOuterDivider && 'has-top-divider',
    bottomOuterDivider && 'has-bottom-divider',
    hasBgColor && 'has-bg-color',
    invertColor && 'invert-color',
    className
  );

  const innerClasses = classNames(
    'testimonial-inner section-inner',
    bottomDivider && 'has-bottom-divider'
  );

  const tilesClasses = classNames(
    'tiles-wrap',
    pushLeft && 'push-left'
  );

  const sectionHeader = {
    title: 'Some of the advanced features of UTStamp',
  };

  return (
    <section
      {...props}
      className={outerClasses}
    >
      <div className="container">
        <div className={innerClasses}>
          <SectionHeader data={sectionHeader} className="center-content" />
          <div className={tilesClasses}>

            <div className="tiles-item" data-reveal-delay="200">
              <div className="tiles-item-inner utstamp-feature">
                <div className="">
                  <div className="center-content">
                    <Image
                      src={require('./../../assets/images/ico-loupe.svg')}
                      alt="Auditing"
                      width={48}
                      height={48} />
                  </div>
                  <h6 className="title-feature">Auditing</h6>
                  <p className="mb-0 text-14">
                    One of the main challenges in auditing is how to verify data integrity. With SKTLab’s UTStamp solution, the raw data (eg. smart meter records, financial records, contracts, etc.) can be registered with UTStamp as it’s being collected. The digital mark that is associated with these records can be easily verified for the integrity of those records. The same approach can be applied to prove the other digital records. There are many applications that can be implemented on top of this, especially in the IoT space.
                  </p>
                </div>
              </div>
            </div>

            <div className="tiles-item">
              <div className="tiles-item-inner utstamp-feature">
                <div className="">
                  <div className="center-content">
                    <Image
                      src={require('./../../assets/images/ico-shield.svg')}
                      alt="Security"
                      width={48}
                      height={48} />
                  </div>
                  <h6 className="title-feature">Security</h6>
                  <p className="mb-0 text-14">
                    UTStamp is completely secure and maintains privacy of data. When the digital documents are being registered with UTStamp, it only requires the one way hashed values of the documents. The information kept in UTStamp are also hash values, therefore the actual contents of the original digital documents do not need to be revealed for proof of time and content.
                  </p>
                </div>
              </div>
            </div>

            <div className="tiles-item" data-reveal-delay="200">
              <div className="tiles-item-inner utstamp-feature">
                <div className="">
                  <div className="center-content">
                    <Image
                      src={require('./../../assets/images/ico-database.svg')}
                      alt="database system"
                      width={48}
                      height={48} />
                  </div>
                  <h6 className="title-feature">Read only database system</h6>
                  <p className="mb-0 text-14">
                    Based on UTStamp technology, SKTLab provides both online and on-premise non-SQL database system, which allows the user to save data as a read-only repository. As long as the data is saved in the database, the content will not be able to be altered. If any data itself or sequence is altered this will be detected.
                  </p>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
    </section>
  );
}

UtstampFeatures.propTypes = propTypes;
UtstampFeatures.defaultProps = defaultProps;

export default UtstampFeatures;